@import "../variable";

.notifTitle {
  padding: 10px 10px 5px 10px;
  font-size: 20px;
  color: #042e59;
  margin: 0 5px;
}

.notifTitle i {
  float: right;
  line-height: 32px;
}

.notifli {
  padding: 10px;
  // margin: 5px;
  background: #eee;
  border-radius: 4px;
  cursor: pointer;
}

.notifli a {
  cursor: pointer;
}

.notifli:hover {
  background: #ddd;
  color: #042e59;
}

.notifli p {
  font-size: 12px;
}

.MuiPaper-rounded {
  border-radius: 0 !important;
}

.MuiPopover-paper {
  // max-height: 389px !important;
  max-width: 420px !important;
  // overflow: scroll !important;

  /* width */

  &::-webkit-scrollbar {
    width: 5px;
    display: none;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $secondaryColor;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.notification-section {
}

.notification {
  padding: 15px 0;

  .header-notification {
    .header-notification-body {
      max-height: 350px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 3px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $secondaryColor;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .notification-heading-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      padding: 0 15px;

      .notification-heading {
        font-size: 18px;
        font-weight: 700;
        // color: $titleColor;
      }

      .notification-icon {
        color: $secondaryColor;
      }

      .chat-icon {
        color: $contentColor;
      }
    }

    .notiful {
      margin: 0;
    }

    .notification-block {
      margin: 0;
      background: $white;
      padding: 15px;
      border-top: 1px solid #c2c3c473;

      &:hover {
        background: $listHoverBg;
      }

      a {
        display: flex;
        align-items: center;
      }

      .notification-content-area {
        margin: 0 60px 0 20px;
      }

      .notification-avatar {
        background: $secondaryColor;
        height: 45px;
        width: 45px;
      }

      .notification-title {
        color: $secondaryColor;
        font-size: 14px;
        font-weight: 700;
      }

      .notification-content {
        font-size: 11px;
        color: $contentColor;
        font-weight: 600;
        // margin-top: 3px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
  }
}

@media (max-width: 992px) {
  .notiful {
    // min-width: 91vw;
    margin: 0;
    margin-bottom: 10px;
  }
  .notification-section {
    margin-right: -10px;
  }
  .MuiPopover-paper {
    // max-height:305px !important;
  }
  .notification {
    .header-notification {
      .header-notification-body {
        max-height: 337px;
      }
    }
  }
}
@media (max-width: 768px) {
  .notification-section {
    margin: 0;
  }
  .MuiPopover-paper {
    // max-height: 278px !important;
  }
  .notification {
    .header-notification {
      .header-notification-body {
        max-height: 317px;
      }
      .notification-heading-block {
        .notification-heading {
          font-size: 16px;
        }
      }
      .notification-block {
        .notification-avatar {
          height: 35px;
          width: 35px;
          font-size: 16px;
        }
        .notification-title {
          font-size: 12px;
        }
        .notification-content {
          font-size: 10px;
        }
        .notification-content-area {
          margin: 0 20px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .MuiPopover-paper {
    max-width: 380px !important;
    // max-height: 246px !important;
    left: 189px !important;
    top: 50px !important;
  }
  .notification {
    padding: 10px 0;
    .header-notification {
      .notification-block {
        padding: 10px;
      }
      .header-notification-body {
        max-height: 199px;

        &::-webkit-scrollbar {
          width: 2px;
        }
      }
    }
  }
}
@media (max-width: 375px) {
  .MuiPopover-paper {
    max-width: 280px !important;
    // max-height: 183px !important;
    left: 167px !important;
    top: 49px !important;
  }
  .notification {
    padding: 10px 0;
    .header-notification {
      .header-notification-body {
        max-height: 141px;
      }
      .notification-heading-block {
        .notification-heading {
          font-size: 14px;
        }
      }
      .notification-block {
        padding: 5px;
        .notification-content-area {
          margin: 0 10px;
        }
        .notification-title {
          font-size: 10px;
        }
        .notification-content {
          font-size: 8px;
        }
        .notification-avatar {
          height: 25px;
          width: 25px;
          font-size: 12px;
        }
        .notification-content {
          -webkit-line-clamp: 2;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .MuiPopover-paper {
    left: 123px !important;
    max-width: 240px !important;
    // max-height: 210px !important;
  }
  .notification {
    .header-notification {
      .notification-heading-block {
        .header-notification-body {
          max-height: 142px;
        }
        .notification-heading {
          font-size: 12px;
        }
      }
    }
  }
}
