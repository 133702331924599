.hideLoader {
    opacity: 0;
    z-index: -99999999999999999999!important;
    transition: all 1s;
}

.showLoader {
    opacity: 1;
}

.fullpageloader {
    width: calc(100vw - (100vw - 100%));
    height: 100vh;
    position: absolute;
    background-color: white;
    z-index: 99999999999999999999;
}

.fullpageloader img {
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}