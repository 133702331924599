@import "../../style/variable";

.quotation-tab {
  .details-wraper {
    display: flex;
    justify-content: space-between;

    .sub-details-wrapper {
      display: flex;
      width: 50%;
    }
    .quotation-content {
      padding: 0 30px;

      .fa-check-circle {
        text-align: center;
        width: 100%;
        font-size: 40px;
        color: $secondaryColor;
      }

      .fa-clock {
        text-align: center;
        width: 100%;
        font-size: 40px;
        color: $infoColor;
      }
      .medium-content {
        font-size: 16px;
        font-weight: 500;
        color: #042e59;
      }

      .bold-content {
        color: #042e59;
        font-weight: 800;
        font-size: 14px;
      }

      .supplier-details {
        background-color: #f5f7f9;
        padding: 10px;
        border-radius: 5px;

        .supplier-head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          .ratings {
            background-color: #efa91b;
            padding: 5px 10px;
            color: #fff;
            font-size: 12px;
            font-weight: 700;
            border-radius: 5px;
          }
        }
        .supplier-bottom {
          font-size: 14px;

          span {
            margin-left: 10px;
          }
        }

        .verified {
          color: $secondaryColor;
          i {
            width: auto;
            font-size: 15px;
          }
        }

        .location {
          color: $titleColor;
          i {
            font-size: 17px;
          }
        }

        .chat-block {
          display: flex;
          align-items: center;
          padding: 10px 15px;
          background: #0f6bb1;
          color: white;
          border-radius: 50px;
          width: fit-content;
          cursor: pointer;

          .content {
            margin-left: 8px;
            color: white;
            font-weight: 700;
          }
        }
      }
    }

    .border-left {
      border-left: 1px solid $border-color;
    }
  }
}

@media (max-width: 992px) {
  .quotation-tab {
    .details-wraper {
      .quotation-content {
        padding: 0 15px;
      }
    }
  }
}

@media (max-width: 768px) {
  .quotation-tab {
    .details-wraper {
      flex-direction: column;

      .sub-details-wrapper {
        width: 100%;
        margin-bottom: 20px;

        .text-center {
          text-align: initial;
          border-left: none;

          i {
            width: auto;
          }
        }
      }
    }
  }
}
