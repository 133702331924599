@import "../../style/variable";

.profile-details-section {
  .card {
    padding: 20px 40px;
    border-radius: 10px 10px 0 0;
    margin-bottom: 0;

    .card-details {
      display: flex;
      img {
        height: 65px;
        width: 65px;
        border-radius: 20px;
        object-fit: contain;
      }

      .no-user-image{
        height: 65px;
        width: 65px;
        border-radius: 20px;
        background-color: #80808091;
        font-size: 30px;
        padding: 10px;
        text-align: center;
        font-weight: 800;
        color: white;
      }

      .user-details {
        margin-left: 10px;

        .user-name {
          font-size: 20px;
          color: #042e59;
          font-weight: 600;
        }

        .company-address {
          font-size: 14px;
          color: #08213a;
        }
      }
    }
  }

  .profile-tab-section {
    .tab-list {
      justify-content: initial;
      margin: 0;
      background-color: $white;
      border-top: 1px solid #042e5926;
      border-radius: 0 0 20px 20px;

      .tab-list-wrap {
        border-bottom: 1px solid $white;

        padding: 10px 16px;
        margin: 0 10px;
      }
    }
    .react-tabs__tab {
      margin: 0;
      padding: 0 !important;
      border-radius: 20px !important;
      h5 {
        font-size: 16px;
        font-weight: 500;
        color: $listColor;
      }
    }

    .react-tabs__tab--selected {
      background: transparent !important;
      border-color: $secondaryColor;
      h5 {
        color: $secondaryColor;
      }

      .tab-list-wrap {
        border-bottom: 1px solid $secondaryColor;
      }
    }

    .tab-panel-card {
      background-color: $white;
      margin-top: 20px;
      padding: 20px;
      border-radius: 10px;
    
    }





  }
}


@media (max-width:480px){
  .profile-tab-section{
    .tab-list-wrap{
      padding: 6px !important;
      .tab-title{
        font-size: 14px !important;
      }
    }
  }
}

@media (max-width: 390px){
  .profile-tab-section{
      .tab-list-wrap{
        .supplier-head{
          flex-direction: column ;
          align-items: self-start;
        }
      }
  }
}