@import "../variable.scss";



.mid-header-section{
    .nav-wrapper{

        .header-login{
            .user-img{
                height: 30px;
                width: 30px;
                border-radius: 50px;
            }


            .menu{
                background: #ffffff;
                border-radius: 8px;
                position: absolute;
                top: 60px;
                right: 0;
                width: auto;
                box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
                opacity: 0;
                visibility: hidden;
                transform: translateY(-20px);
                transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
                height: auto;
                z-index: 999;
                margin-top: 10px;

                img{
                    color: black;
                }
            }

            .menu.active {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
              }
            menu ul {
                display: flex;
                flex-direction: column;
                color: red;
                list-style: none;
                padding: 0;
                margin: 0;
              }
              
              .menu li {
                border-bottom: 1px solid #dddddd;
                float: none;
                list-style-type: none;

                .fa-user-circle{
                    font-size: 17px;
                }
              }
              
              .menu li a {
                text-decoration: none;
                color: #333333a6;
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 600;

                i{
                    margin-right: 8px;
                    font-size: 15px;
                }
              }


              .menu li :hover{
                color: green;
                i{
                    color: #31a16a;
                }
              }
        }
        .login-section{
            margin: auto;
            cursor: pointer;;
            .login-btn{
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                border: 2px solid white;
                padding: 8px 14px;
                border-radius: 5px;
            

                i{
                    margin-right: 10px;
                    font-size: 17px;
                    height: auto;
                    line-height: 10px;
                    margin-top: 1px;
                }

                p{
                    font-size: 16px;
                    margin-top: 0;
                    line-height: 25px;
                    font-weight: 700;
                }
            }
        }
     
    }
}
