@import "../variable";
.loading{
    height: 75vh;
    display: grid;
    place-items: center;
}
.loader{
    width: 80px;
    height: 80px;
    position: relative;
    margin: 40px auto;
    
}
.loader .loader-inner{
    position: absolute;
    width: 100%;
    height: 100%;
    -moz-animation: loading1 8000ms infinite linear;
    -o-animation: loading1 8000ms infinite linear;
    -webkit-animation: loading1 8000ms infinite linear;
    animation: loading1 8000ms infinite linear;
}
.loader .loader-inner div{
    width: 50%;
    height: 50%;
    border-radius: 50%;
    -moz-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -o-transform: scale(0.1);
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.1;
}
.loader .loader-inner div:nth-child(1){
    position: absolute;
    top: 0;
    left: 0;
    background: #03a9f4;
    -moz-animation: loading2 1000ms infinite ease alternate;
    -o-animation: loading2 1000ms infinite ease alternate;
    -webkit-animation: loading2 1000ms infinite ease alternate;
    animation: loading2 1000ms infinite ease alternate;
    -moz-animation-delay: 0;
    -o-animation-delay: 0;
    -webkit-animation-delay: 0;
    animation-delay: 0;
}
.loader .loader-inner div:nth-child(2) {
    position: absolute;
    top: 0;
    left: 50%;
    background: #f44336;
    -moz-animation: loading2 1000ms infinite ease alternate;
    -o-animation: loading2 1000ms infinite ease alternate;
    -webkit-animation: loading2 1000ms infinite ease alternate;
    animation: loading2 1000ms infinite ease alternate;
    -moz-animation-delay: 250ms;
    -o-animation-delay: 250ms;
    -webkit-animation-delay: 250ms;
    animation-delay: 250ms;
}
.loader .loader-inner div:nth-child(3) {
    position: absolute;
    top: 50%;
    left: 0;
    background: #8bc34a;
    -moz-animation: loading2 1000ms infinite ease alternate;
    -o-animation: loading2 1000ms infinite ease alternate;
    -webkit-animation: loading2 1000ms infinite ease alternate;
    animation: loading2 1000ms infinite ease alternate;
    -moz-animation-delay: 500ms;
    -o-animation-delay: 500ms;
    -webkit-animation-delay: 500ms;
    animation-delay: 500ms;
}
.loader .loader-inner div:nth-child(4) {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #ffc107;
    -moz-animation: loading2 1000ms infinite ease alternate;
    -o-animation: loading2 1000ms infinite ease alternate;
    -webkit-animation: loading2 1000ms infinite ease alternate;
    animation: loading2 1000ms infinite ease alternate;
    -moz-animation-delay: 750ms;
    -o-animation-delay: 750ms;
    -webkit-animation-delay: 750ms;
    animation-delay: 750ms;
}
@-webkit-keyframes loading1{
    from {
        transform: rotate(-180deg);
    }
    to {
        transform: rotate(180deg);
    }
}
@keyframes loading1{
    from {
        transform: rotate(-180deg);
    }
    to {
        transform: rotate(180deg);
    }
}
@-webkit-keyframes loading2{
    from {
        top: -50%;
        left: -50%;
        transform: scale(1.0);
        opacity: 0;
    }
    to {
        transform: scale(0.2);
        opacity: 0.8;
    }
}
@keyframes loading2{
    from {
        top: -50%;
        left: -50%;
        transform: scale(1.0);
        opacity: 0;
    }
    to {
        transform: scale(0.2);
        opacity: 0.8;
    }
}