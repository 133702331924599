/*---------------------------------
    Horizontal Menu
    -----------------------------------*/
#main,
.main-full,
footer {
  padding-left: 0 !important;
}

header nav.navbar-color {
  -webkit-box-shadow: none;
  box-shadow: none;
}

header .brand-logo {
  font-size: 2.1rem;
  line-height: 0;

  position: absolute;
  top: 0;

  display: inline-block;

  margin: 1px 0;
  padding: 22px 12px;

  color: #fff;
}
header .brand-logo img {
  height: 45px;
}
header .brand-logo .logo-text {
  font-size: 24px;

  visibility: visible;

  padding-left: 8px;

  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;

  opacity: 1;
}

@media only screen and (max-width: 992px) {
  header nav.navbar-color ul.left {
    position: relative;
    top: 0;
    left: 30%;
  }
  /* header .brand-logo {
    position: absolute;
    right: 175px;
  } */
}

.horizontal-layout .sidenav-main .sidenav-trigger {
  position: absolute;
  top: 7px;
  left: 10px;
}

#header .header-search-wrapper {
  margin: 10px auto 0 260px;
}

#ul-horizontal-nav > li > a {
  padding: 0 18px;
}

.navbar-fixed #horizontal-nav {
  margin-top: 64px;
}

@media only screen and (min-width: 993px) {
  #main {
    margin-top: 15px;
  }
}

.doughnut-chart-status {
  top: -85px;
}

#horizontal-nav {
  -webkit-box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.04),
    0 1px 5px 0 rgba(0, 0, 0, 0.02), 0 3px 1px -2px rgba(0, 0, 0, 0.02);
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 5px 0 rgba(0, 0, 0, 0.02),
    0 3px 1px -2px rgba(0, 0, 0, 0.02);
}
#horizontal-nav ul li {
  text-align: center;
}
#horizontal-nav ul li a i {
  font-size: 1.4rem;
  line-height: 45px;

  display: block;

  height: 0;

  color: #787878;
}
#horizontal-nav ul li a span {
  position: relative;
  top: 14px;

  color: rgba(0, 0, 0, 0.87);
}
#horizontal-nav .dropdown-menu span i {
  font-size: 14px;

  position: absolute;
  top: -12px;
  right: -14px;
}

.dropdown-content li {
  min-height: 43px;
}
.dropdown-content li a {
  color: rgba(0, 0, 0, 0.87);
}

.dropdown-horizontal-list li a {
  font-size: 1rem;
  font-weight: 400;

  min-width: 150px;
  padding: 10px;

  text-align: left;
}

/*IE Specific CSS */
@media screen and (min-width: 0\0) and (-webkit-min-device-pixel-ratio: 0.75),
  screen and (min-width: 0\0) and (-o-min-device-pixel-ratio: 3/4),
  screen and (min-width: 0\0) and (min-resolution: 72dpi) {
  #horizontal-nav ul li {
    min-width: 110px;
  }
  #horizontal-nav .dropdown-menu span i {
    line-height: 0;

    position: absolute;
    top: 10px;
    right: -20px;
  }
}
/* @media (max-width: 992px){
  .popup-img {
    position: unset;
    display: flex;
    justify-content: flex-start;
  }
}

@media (max-width: 768px){
  .popup-img {
    right: 240px;
  }
}

@media (max-width: 480px){
  .popup-img {
    right: 155px;
  }
}
@media(max-width: 320px){
  .popup-img{
    right: 100px;
  }
} */