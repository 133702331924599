@import "../style/variable.scss";

.facebook-login-section{
    button{
        color: $listColor ;
        background: white;
    border: none;
    text-transform: capitalize;
    font-weight: 500;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

        i{

    background: $listColor;
    color: $white;
    padding: 7px 7px 0 7px;
    font-size: 19px;
    font-weight: 900;
    margin-right: 10px;

        }
    }
}