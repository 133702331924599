/*-------------------------------------------------
  Dashboard common file for Analytics & eCommmerce
--------------------------------------------------*/
#revenue-chart span i.material-icons
{
    position: relative; 
    top: 5px;
}

#revenue-chart .yearly-revenue-chart
{
    position: relative;
}
#revenue-chart .yearly-revenue-chart canvas
{
    width: 100% !important;
    height: 350px !important;
}
#revenue-chart .yearly-revenue-chart canvas.firstShadow
{
    position: absolute;
    top: 0; 
    left: 0;
}

#weekly-earning h3.header
{
    margin: 2.4rem 0 2.3rem 0;
}

#weekly-earning canvas
{
    width: 100% !important;
    height: 110 !important;
    margin: 10px 0 10px -18px;
}

#weekly-earning .center-align p
{
    margin-bottom: 1rem;
}
