@import "../../style/variable";

.no-data-content {
    background: white;
    margin: 10px 0;
    padding: 40px;
    text-align: center;
    height: 200px;
    border-radius: 10px;

    img{
        height: 100px;
    }

    p{
        margin: 10px 0;
        color: #31a268;
        font-weight: 600;
        font-size: 14px;
    }
}