.chat-card-section {
  background-color: #fff;
  border-radius: 20px;
  margin-top: 25px;
  border: 1px solid #e8e8e8;
  .chat-section {
    display: flex;
    .row {
      height: 100%;
    }

    .chat-list-block {
      height: 500px;
      overflow-y: scroll;
      width: 25%;

      .chat-list-header {
        padding: 15px 20px;
        width: 100%;
        margin-bottom: 10px;

        p {
          margin-bottom: 0;
        }
      }

      .chat-user {
        padding: 15px 20px;
        display: flex;
        align-items: center;
        // border-bottom: 1px solid #e8e8e8;
        cursor: pointer;
        border-radius: 10px;
        margin: 0 5px;

        &:hover {
          background-color: #f1f3f4;
        }
        img {
          height: 30px;
          width: 30px;
          border-radius: 50px;
          margin-right: 8px;
        }

        p {
          margin-bottom: 0;
          font-size: 16px;
        }
      }

      .active {
        background: #f4f8fb;

        &:hover {
          background: #f4f8fb;
        }
      }
    }

    .message-list-block {
      width: 75%;
      position: relative;
      display: flex;
      align-items: end;

      .message-list-header {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        padding: 12px 20px;
        width: 100%;
        border-bottom: 1px solid #e8e8e8;

        img {
          height: 30px;
          width: 30px;
          margin-right: 10px;
        }
        p {
          margin-bottom: 0;
        }
      }
      .message-list {
        width: 100%;
        height: 380px;
        margin-bottom: 65px;
        overflow-y: scroll;
        padding: 10px 20px;

        .sender_message-card {
          display: flex;
          margin-bottom: 15px;
          align-items: center;
          margin: 15px 0;

          p {
            padding: 8px 18px;
            background-color: #f1f3f4;
            border-radius: 20px;
            width: auto;
            color: #01091278;
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 0;
          }

          img {
            height: 30px;
            width: 30px;
            border-radius: 50px;
            margin-right: 10px;
            border: 1px solid #e8e8e8;
          }
        }

        .recipient-message-card {
          display: flex;
          justify-content: end;
          align-items: center;
          margin: 15px 0;

          p {
            padding: 8px 18px;
            background: #0963bb;
            border-radius: 20px;
            width: auto;
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 0;
          }

          img {
            height: 30px;
            width: 30px;
            border-radius: 50px;
            margin-left: 10px;
            border: 1px solid #e8e8e8;
          }
        }
      }

      .chat-input {
        position: absolute;
        bottom: 20px;
        width: 100%;

        .message-send {
          padding: 10px 0px 0 10px;
        }
        textarea {
          resize: none;
          width: 95%;
          border: 1px solid #e8e8e8;
          padding: 10px 20px;
          height: 50px;
          overflow-y: hidden;
          font-size: 18px;
          line-height: 25px;
          border-radius: 50px;
          background: #f1f3f4;
        }

        .send-btn {
          font-size: 20px;
          padding: 10px;
          color: #bdc1c5;
          cursor: pointer;
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }
}
