.s003 form {
    width: 95%;
    margin: auto;
    max-width: 720px;
    margin-bottom: 0;
    height: 50px;
    line-height: 50px;
}

.s003 form .inner-form {
    background: #fff;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}

.s003 form .inner-form .input-field {
    height: 50px;
    line-height: 50px;
}

.s003 form .inner-form .input-field input {
    height: 100%;
    background: 0 0;
    border: 0;
    width: 100%;
    padding: 0px 20px;
    font-size: 17px;
    color: #042e59;
    text-transform: capitalize;
}

.select-wrapper {
    position: relative;
    height: 100%;
}

.s003 form .inner-form .input-field input.placeholder {
    color: rgba(4, 46, 89, 0.3);
    font-size: 17px;
}

.s003 form .inner-form .input-field input:-moz-placeholder {
    color: rgba(4, 46, 89, 0.3);
    font-size: 17px;
}

.s003 form .inner-form .input-field input::-webkit-input-placeholder {
    color: rgba(4, 46, 89, 0.3);
    font-size: 17px;
}

.s003 form .inner-form .input-field input:focus,
.s003 form .inner-form .input-field input:hover {
    box-shadow: none;
    outline: 0;
    border-color: transparent;
    border-bottom: none;
}

.s003 form .inner-form .input-field.first-wrap {
    width: 150px;
    padding: 10px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.s003 form .inner-form .input-field.first-wrap .choices__inner {
    background: 0 0;
    border-radius: 0;
    border: 0;
    height: 100%;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 30px;
}

.input-select {
    color: #042e59;
    height: auto;
    line-height: 30px;
    text-align: center;
    font-weight: 600;
}

.s003 form .inner-form .input-field .input-select select {
    border: none;
}

.s003 form .inner-form .input-field.first-wrap .choices__inner .choices__list.choices__list--single {
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.s003 form .inner-form .input-field.first-wrap .choices__inner .choices__item.choices__item--selectable.choices__placeholder {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    opacity: 1;
    color: #888;
}

.s003 form .inner-form .input-field.first-wrap .choices__inner .choices__list--single .choices__item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    color: #555;
}

.s003 form .inner-form .input-field.first-wrap .choices[data-type*="select-one"]:after {
    right: 30px;
    border-color: #e5e5e5 transparent transparent transparent;
}

.s003 form .inner-form .input-field.first-wrap .choices__list.choices__list--dropdown {
    border: 0;
    background: #fff;
    padding: 20px 30px;
    margin-top: 2px;
    border-radius: 4px;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
}

.s003 form .inner-form .input-field.first-wrap .choices__list.choices__list--dropdown .choices__item--selectable {
    padding-right: 0;
}

.s003 form .inner-form .input-field.first-wrap .choices__list--dropdown .choices__item--selectable.is-highlighted {
    background: #fff;
    color: #63c76a;
}

.s003 form .inner-form .input-field.first-wrap .choices__list--dropdown .choices__item {
    color: #555;
    min-height: 24px;
}

.s003 form .inner-form .input-field.second-wrap {
    -ms-flex-positive: 1;
    flex-grow: 1;
}
.s003 form .inner-form .input-field.second-wrap .css-1652bmz-placeholder{
font-size: 17px;
color: rgba(4, 46, 89, 0.3);
}

.s003 form .inner-form .input-field.third-wrap {
    width: 74px;
}

.s003 form .inner-form .input-field.third-wrap .btn-search {
    height: 95%;
    width: 96%;
    white-space: nowrap;
    color: rgba(4, 46, 89, 0.3);
    border: 0;
    cursor: pointer;
    background: transparent;
    transition: all 0.2s ease-out, color 0.2s ease-out;
    border-radius: 3px;
    margin-top: 2%;
}

.s003 form .inner-form .input-field.third-wrap .btn-search svg {
    width: 16px;
}


/* .s003 form .inner-form .input-field.third-wrap .btn-search:hover {
  background: #0657b3;
} */

.s003 form .inner-form .input-field.third-wrap .btn-search:focus {
    outline: 0;
    box-shadow: none;
}

@media screen and (max-width: 992px) {
    .s003 form .inner-form .input-field {
        height: 50px;
    }
}

@media screen and (max-width: 767px) {
    .s003 form .inner-form {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 20px;
    }
    .s003 form .inner-form .input-field {
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .s003 form .inner-form .input-field input {
        padding: 10px 15px;
    }
    .s003 form .inner-form .input-field.first-wrap {
        width: 100%;
        border-right: 0;
    }
    .s003 form .inner-form .input-field.first-wrap .choices__inner {
        padding: 10px 15px;
    }
    .s003 form .inner-form .input-field.first-wrap .choices[data-type*="select-one"]:after {
        right: 11.5px;
        border-color: #e5e5e5 transparent transparent transparent;
    }
    .s003 form .inner-form .input-field.second-wrap {
        width: 100%;
        margin-bottom: 30px;
    }
    .s003 form .inner-form .input-field.second-wrap input {
        border: 1px solid rgba(255, 255, 255, 0.3);
    }
    .s003 form .inner-form .input-field.third-wrap {
        margin-bottom: 0;
        width: 100%;
    }
}