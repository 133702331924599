a {
  color: unset;
}

.header-top {
  height: 40px;
  line-height: 40px;
}

.icon img {
  width: 20px;
}

.heading h3 {
  color: #042e59;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  margin: 0;
  padding: 10px 0px 0px 10px;
}

.heading {
  color: #042e59;
  font-weight: 700;
}

.lists-style a {
  color: rgba(4, 46, 89, 0.7);
  text-decoration: none;
  padding-left: 10px;
  transition: 0.5s;
}

.lists-style:hover a {
  color: #efa91b;
}

.lists {
  list-style: none;
  line-height: 270%;
  padding: 0px 10px;
}

.product-list:hover {
  border-left: 3px solid #efa91b;
  background: rgba(239, 169, 27, 0.05);
}

.product-list:active,
.product-list:focus,
.product-list:focus-within,
.product-list:visited {
  background: #e5e5e5;
}

.product-list:hover .lists-style a {
  color: #efa91b;
  transition: 0.5s;
}

.product-list {
  display: flex;
  border-left: 3px solid white;
  transition: 0.5s;
  width: 100%;
}

.swiper-img {
  width: 100%;
}

.title-text {
  font-weight: 700;
  font-size: 30px;
  color: #042e59;
  line-height: 33px;
  padding: 0 ;
  margin: 15px;
}

.see-all-btn {
  color: #042e59;
  float: right;
  font-size: 18px;
}

.deals-card {
  background-color: white;
  transition: 0.5s;
  padding: 12px;
}

.swiper-slide {
  transition: 0.5s;
}

/* .swiper-slide:hover {
  box-shadow: 1px 1px 15px #ccc;
  transition: 0.5s;
} */

.deal-img img {
  height: auto;
  width: 100%;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.deal-item-desc {
  text-align: left;
}

.deal-item-desc h5 {
  color: #042e59;
  margin: 8px 0px;
  font-size: 15px;
}

.shopNow {
  color: #53bb5d;
  line-height: 18px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}

.product-card {
  background-color: #ffffff;
  border-radius: 12px;
  transition: 0.5s;
  padding: 12px;
}

.product-img img {
  height: auto;
  width: 100%;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.price-desc {
  margin-bottom: 10px;
}

.product-desc {
  text-align: left;
}

.product-desc h4 {
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4f6d8b;
  font-size: 16px;
}

.product-pay-price {
  color: #042e59;
  font-size: 18px;
  font-weight: 700;
  margin-right: 8px;
}

.pay-price {
  color: #042e59;
  font-size: 16px;
  line-height: 23px;
  font-weight: 600;
  margin-right: 8px;
}

.mrp {
  text-decoration: line-through;
  color: rgba(4, 46, 89, 0.4);
  margin-right: 8px;
  line-height: 18px;
  font-size: 14px;
}

.product-discount-desc {
  color: #53bb5d;
  font-size: 16px;
  font-weight: 400;
  margin-right: 8px;
}

.discount-desc {
  color: #53bb5d;
  line-height: 18px;
  font-size: 14px;
  font-weight: 400;
  margin-right: 8px;
}

.addtocart-button {
  color: #efa91b;
  background-color: rgba(239, 169, 27, 0.1);
  border: none;
  border-radius: 5px;
  padding: 10px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  width: 60%;
  transition: 0.5s;
}

.addtocart-button:hover {
  color: #fff;
  background-color: #efaa38;
  transition: 0.5s;
}

.more-services {
  transition: 0.5s;
}

.more-services:hover {
  box-shadow: 1px 1px 15px #ccc;
  transition: 0.5s;
}

.more-lists {
  color: #53bb5d !important;
  text-decoration: none;
}

.service-card:hover {
  box-shadow: 1px 1px 15px #ccc;
  transition: 0.5s;
}

.image-style img {
  width: 100%;
  height: auto;
  float: right;
}

.service-names h5 {
  font-weight: 700;
  font-size: 18px;
  color: #042e59;
  margin: 10px;
}

.service-list {
  list-style: none;
  padding: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 200%;
  margin: 10px;
}

.list {
  color: rgba(4, 46, 89, 0.7);
  transition: 0.5s;
  font-weight: 500;
}

.list:hover {
  color: #efa91b;
  transition: 1s;
  position: relative;
  transition: 0.5s;
}

.more-lists {
  color: #53bb5d !important;
  text-decoration: none;
}

.shopNow-pop {
  color: #53bb5d;
}

.popular-item-desc {
  text-align: center;
}

.popular-card {
  padding: 1em 0;
}

.popular-card:hover {
  box-shadow: 2px #ccc;
}

.popular-img img {
  width: 100%;
  height: auto;
}

.popular-item-desc h5 {
  color: #042e59;
  font-weight: 500;
  font-size: 20px;
}

.subscribe-content h5 {
  color: #269116;
}

.subscribe-content p {
  color: #4e4e4e;
}

.bottom-cards {
  padding: 40px 10px;
  height: 200px;
}

.bottom-cart-b-1 {
  border: 2px solid rgba(4, 46, 89, 0.05);
  border-radius: 10px 0 0 10px;
}

.bottom-cart-b-2 {
  border-top: 2px solid rgba(4, 46, 89, 0.05);
  border-right: 2px solid rgba(4, 46, 89, 0.05);
  border-bottom: 2px solid rgba(4, 46, 89, 0.05);
}

.bottom-cart-b-3 {
  border-top: 2px solid rgba(4, 46, 89, 0.05);
  border-right: 2px solid rgba(4, 46, 89, 0.05);
  border-bottom: 2px solid rgba(4, 46, 89, 0.05);
  border-radius: 0 10px 10px 0;
}

.list-heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;
}

.copyright-text {
  line-height: 6;
}

.footer-copyright {
  background: rgba(245, 247, 249, 0.08);
}

.footer-list li {
  /* font-weight: 500; */
  font-size: 18px;
  line-height: 230%;
}

.footer-more {
  font-size: 17px;
  line-height: 140.7%;
  color: #ffffff;
}

.footer-logo {
  width: 150px;
}

.single-icon {
  padding-right: 10px;
}

.icons-text h5 {
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
}

.b-r-10 {
  border-radius: 10px !important;
}

.b-r-12 {
  border-radius: 12px !important;
}

.b-r-5 {
  border-radius: 5px !important;
}

.card-bottom {
  height: 45px;
  line-height: 45px;
}

.wishlist {
  padding: 10px;
}

.deals-card:hover .deal-img img,
.product-card:hover .product-img img {
  transform: scale(1.05);
}

/* ----------------------product page css-------------------------- */

.product-page-up {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 3px solid #f5f7f9;
}

.product-sidebar,
.product-feature,
.price-sidebar,
.color-sidebar {
  border-bottom: 2px solid #f5f7f9;
}

.product-page-up h5 {
  font-weight: 600;
  font-size: 24px;
  color: #042e59;
}

.product-page-up a {
  font-weight: 500;
  font-size: 14px;
  color: #ef4f2c;
  float: right;
  line-height: 50px;
}

.chip-area {
  padding: 10px;
}

.more-icon {
  color: #53bb5d;
}

.more-brand {
  padding: 10px 0;
}

.product-feature {
  padding: 0px 20px;
}

.price-sidebar {
  padding: 20px;
}

.display-grid {
  padding: 10px;
}

.single-brand {
  line-height: 300%;
}

.price-slider {
  width: 100%;
  height: 6px;
  background: #53bb5d;
  border-radius: 10px;
}

.select {
  background-color: transparent;
}

.sortby {
  display: flex;
  border: 1px solid #ffffff;
  background: #ffffff;
  border-radius: 5px;
  justify-content: space-around;
  align-items: center;
  height: 40px;
}

/* .sortby span.label {
  width: 25%;
  line-height: 45px;
} */

.sortby select {
  /* display: block !important; */
  width: 75%;
  border-bottom: none;
}

.category-product-title h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  color: #042e59;
}

.category-product-title span {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #042e59;
}

.heart-img {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 24px;
  color: #fff;
}

/* .pagination-section {
  display: flex;
  align-items: center;
} */

.page-no {
  /* line-height: 37px; */
  color: rgba(4, 46, 89, 0.7);
  padding-left: 25px;
  font-weight: 600;
}

li.next-button-pagination a {
  border-radius: 5px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

.next-button-pagination {
  background: #53bb5d;
  width: unset !important;
}

ul.pagination li {
  border-radius: 5px;
  margin-right: 5px;
}

.pagination-arrow {
  display: flex !important;
  align-items: center;
}

.pagination-arrow .MuiSvgIcon-root {
  font-size: 16px;
  margin-left: 5px;
  margin-top: 2px;
}

.prev-arrow {
  font-size: 16px !important;
  margin-top: 10px;
}

li.breadcrumb-item a {
  color: rgba(4, 46, 89, 0.7);
}

p.product-name {
  color: rgba(4, 46, 89, 0.7);
  display: -webkit-inline-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  height: 42px;
}

.btn-secondary {
  background: #efa91b;
  border-width: 4px;
  border: 2px solid #efa91b;
}

.btn-primary {
  background: #53bb5d;
  border-width: 4px;
  border: 2px solid #53bb5d;
  /* margin-left: 10px; */
}

.single-product-desc h5 {
  font-weight: 700;
  font-size: 20px;
  color: #042e59;
}

.single-product-desc span {
  font-weight: 500;
  font-size: 15px;
  color: rgba(4, 46, 89, 0.7);
}

.marked-price span {
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  color: rgba(4, 46, 89, 0.4);
}

.img {
  width: 100%;
}

.description-content p {
  color: rgba(4, 46, 89, 0.7);
  font-weight: 400;
  font-size: 17px;
}

.description-title {
  font-weight: 700;
  margin-right: 10px;
}

.description-heading h5 {
  font-weight: 700;
  font-size: 21px;
  color: #042e59;
}

.button-proceed {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px 20px;
  white-space: nowrap;
  border-width: 2px;
  transition: 0.4s;
  backface-visibility: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18);
  background: #efa91b;
  width: 100%;
}

.hr-line {
  background: #e5e5e5 !important;
}

.cart-item-desc h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #042e59;
}

.cart-item-desc span {
  font-size: 14px;
  line-height: 18px;
  color: #53bb5d;
}

.button-apply {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px 23px;
  white-space: nowrap;
  border-width: 2px;
  transition: 0.4s;
  backface-visibility: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18);
}

.button {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  padding: 7px 20px;
  white-space: nowrap;
  border-width: 2px;
  transition: 0.4s;
  backface-visibility: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18);
}

.button-icon,
a.btn-secondary svg {
  width: 17px;
  margin-right: 10px;
  font-size: 10px;
  line-height: 14px;
  vertical-align: middle;
  transition: 0s;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background: transparent;
  border-color: #efa91b;
  border-width: 4px;
  border: 2px solid #efa91b;
  color: #efa91b;
}

.btn-primary:hover,
.btn-primary:focus {
  background: transparent;
  border-color: #53bb5d;
  border-width: 4px;
  border: 2px solid #53bb5d;
  color: #53bb5d;
}

.checkbox-img {
  width: 120px;
  height: 120px;
}

.summary-heading a,
.summary-price {
  font-size: 15px;
  line-height: 25px;
  color: rgba(4, 46, 89, 0.7);
}

.summary-heading p {
  font-size: 12px;
}

.code-input input {
  border-radius: 10px;
  width: 100%;
  border: 1px solid #ffffff !important;
  background: #ffffff !important;
}

.summary-total span,
.summary-total span a {
  font-size: 17px;
  line-height: 30px;
  text-align: right;
  color: #efa91b;
}

.select-delete,
.summary-detail {
  display: flex;
  justify-content: space-between;
}

.delete-all-btn {
  font-size: 14px;
  line-height: 23px;
  text-align: right;
  color: rgba(4, 46, 89, 0.7);
}

.inc-dec-div {
  display: flex;
}

.minus {
  border-radius: 5px;
}

.btn-increment-decrement {
  width: 40px;
  height: 40px;
  padding: 5px;
  text-align: center;
  background-color: #e1e1e1;
  font-size: 25px;
  cursor: pointer;
  background: #ffffff;
  border: 2px solid rgba(4, 46, 89, 0.05);
  box-sizing: border-box;
  border-radius: 5px;
  line-height: 23px;
}

.inc-dec-div input.input-quantity {
  background: #ffffff;
  border: 2px solid rgba(4, 46, 89, 0.05);
  box-sizing: border-box;
  border-radius: 5px;
  width: 60px;
  height: 40px;
  text-align: center;
  font-size: 17px;
  color: rgba(4, 46, 89, 0.7);
}

.plus {
  border-radius: 5px;
}

.cart-checkbox {
  position: absolute;
  z-index: 0;
  top: 45px;
  left: 0;
  width: 26px;
  height: 26px;
  content: "";
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  border: 2px solid #e5e4e2;
  border-radius: 5px;
}

.quantity {
  position: relative;
  top: -12px;
}

.inc-dec-div span {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  padding-top: 10px;
  margin-right: 5px;
  color: #042e59;
}

.heart-icon {
  border: 2px solid rgba(4, 46, 89, 0.03);
  background: rgba(4, 46, 89, 0.03);
  border-radius: 5px;
  padding: 9px;
}

.heart-delete {
  display: flex;
  position: relative;
  top: -15px;
}

.delete-icon {
  border: 2px solid #ef4f2c;
  background: #ef4f2c;
  border-radius: 5px;
  color: #ffffff;
  padding: 11px;
}

.cart-checkbox {
  position: absolute;
  z-index: 0;
  top: 20px;
  left: 0;
  width: 26px;
  height: 26px;
  content: "";
}

.order-summary-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: rgba(4, 46, 89, 0.7);
  border-bottom: 2px solid #f5f7f9;
  padding: 10px 0;
  margin: 0;
}

.order-summary-total {
  border-top: 2px solid #f5f7f9;
  padding: 10px 0;
}

.dropdown-category {
  width: 100%;
}

.dropdown-category i {
  padding: 8px 0 0 0;
  float: right;
  color: rgba(78, 78, 78, 0.4);
}

.checkbox-dropdown label {
  padding: 10px;
}

.checkbox-dropdown {
  padding-top: 10px;
}

.register-heading h4 {
  font-weight: 700;
  font-size: 30px;
  color: #042e59;
}

.register-heading p {
  font-weight: 500;
  font-size: 16px;
  color: rgba(4, 46, 89, 0.7);
}

#input-wrapper label {
  z-index: 99;
  line-height: 25px;
  padding: 12px 0;
  margin-left: 5px;
  position: absolute;
  color: rgba(4, 46, 89, 0.7);
  font-size: 15px;
  margin: 0 3px 0 10px;
}

#input-wrapper input {
  text-indent: 40px;
}

.password-icon i {
  position: absolute;
}

.field-icon {
  float: right;
  margin-top: -40px;
  margin-right: 15px;
  position: relative;
  z-index: 2;
  color: rgba(206, 206, 206, 0.64);
}

.requirement textarea {
  box-shadow: none;
  background: #ffffff;
  border: 2px solid rgba(4, 46, 89, 0.1);
  border-radius: 5px;
  padding: 10px;
  resize: none;
}

.customer-base-heading {
  display: flex;
}

.customer-base-heading h4 {
  font-weight: 500;
  font-size: 22px;
  color: rgba(4, 46, 89, 0.7);
  margin-left: 10px;
}

.supplier-register-image img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.customer-base-readmore {
  color: #53bb5d;
}

.customer-base p {
  display: -webkit-inline-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.register-bottom {
  display: flex;
  justify-content: space-between;
}

.account-login {
  font-weight: 600;
  font-size: 14px;
  color: #46d159;
  padding: 10px;
}

.right-agent-icons {
  background: #f5f7f9;
  text-align: center;
}

.popup-card {
  background: #065dc4;
}

.process {
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-sizing: border-box;
  height: 60px;
  position: relative;
  margin-bottom: 35px;
}

.popup-desc {
  display: flex;
  /* justify-content: space-between; */
}

.popup-desc h4 {
  color: rgba(255, 255, 255, 0.08);
  font-weight: 900;
  margin: 0;
  line-height: 60px;
}

.popup-desc span {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  padding: 6px 0 0 10px;
}

.popup-heading h4 {
  font-weight: 900;
  font-size: 24px;
  text-align: center;
  color: #ffffff;
}

.list-price {
  display: flex;
  justify-content: space-between;
}

.more-details {
  color: #53bb5d;
  font-size: 14px;
  font-weight: 500px;
}

.supplier-detail {
  background: rgba(83, 187, 93, 0.05);
  border-radius: 5px;
  height: 100px;
  padding: 15px 20px;
}

.product-name-grid {
  font-weight: 600;
  font-size: 16px;
  color: rgba(4, 46, 89, 0.7);
}

.price-per-piece {
  font-weight: 800;
  font-size: 18px;
  color: #042e59;
}

.per-piece {
  font-weight: 400;
  font-size: 14px;
  color: rgba(4, 46, 89, 0.7);
}

.min-order {
  font-weight: 700;
  font-size: 14px;
  color: rgba(4, 46, 89, 0.7);
  padding-top: 9px;
}

.min-order span {
  font-weight: 400;
  font-size: 14px;
  color: rgba(4, 46, 89, 0.7);
}

.product-feature {
  font-weight: 500;
  font-size: 14px;
  color: rgba(4, 46, 89, 0.7);
}

.supplier-name {
  font-weight: 800;
  font-size: 16px;
  color: rgba(4, 46, 89, 0.7);
}

.supplier-address {
  font-weight: 400;
  font-size: 14px;
  color: rgba(4, 46, 89, 0.7);
}

.verified-supplier span {
  font-weight: 400;
  font-size: 12px;
  color: #53bb5d;
}

.contact-supplier {
  display: flex;
  justify-content: space-between;
}

.contact-button {
  color: #53bb5d;
  background: rgba(83, 187, 93, 0.05);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  padding: 10px 20px;
}

.contact-button a {
  color: #53bb5d;
}

.compare {
  background: rgba(239, 169, 27, 0.05);
  border-radius: 5px;
  padding: 10px 15px;
}

.chats {
  background: rgba(3, 169, 244, 0.05);
  border-radius: 5px;
  padding: 10px 15px;
}

.list-view-img {
  max-width: 100%;
  height: auto;
  border-radius: 10px 0px 0px 10px;
}

@media only screen and (max-width: 600px) {
  .header-top,
  .mobile-hide,
  .mobile-top-header-hide {
    display: none;
  }
  .quantity {
    position: relative;
    top: 0px;
  }
  .heart-delete {
    display: flex;
    position: relative;
    top: 0px;
  }
  .button-apply {
    padding: 10px 42px;
  }
}

[type="checkbox"].cart-checkbox:not(:checked) + span:not(.lever):after,
[type="checkbox"].cart-checkbox:checked + span:not(.lever):before,
[type="checkbox"].cart-checkbox:checked + span:not(.lever):after {
  top: 45px !important;
}

.nav-text {
  font-weight: 500;
  font-size: 18px;
}

.middle-nav-right {
  height: 100%;
  display: flex;
 
}

.middle-nav-center {
  width: calc(100% - 600px) !important;
}

.middle-nav-btn {
  width: 180px;
  /* height: 100%; */
}

.middle-nav-left {
  width: 180px;
  height: 100%;
}

.middle-nav-row {
  line-height: 103px;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.middle-nav {
  height: 90px;
  /* line-height: 90px; */
}

.middle-nav-button {
  /* height: 50px; */
  line-height: 45px;
  padding: 0 25px;
  text-align: center;
}

.p-h-3px {
  padding: 0 3px !important;
}

.input-field-half {
  width: 94% !important;
}

.input-field-full {
  width: 97% !important;
}

/* Mega menu css */

.mega-menu-wrapper {
  background-color: white;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.nav-sticky{
  position: sticky;
  top: 0;     
  z-index: 11;    
  width: 100%;
  /* pointer-events: none; */
}

.mega-menu {
  display: flex;
  justify-items: center;
  justify-content: unset;
  overflow: auto;
}

.category-p {
  display: table;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.mega-menu .category {
  padding: 25px 8px;
  cursor: pointer;
  color: #333;
  display: flex;
  align-items: center;
  line-height: 113%;
  font-size: 13px;
  min-width: 135px;
}

.mega-menu .category:hover {
  color: #53bb5d;
  cursor: pointer;
}

.menu-content {
  /* border-top: 1px solid #ddd; */
  position: absolute;
  margin-top: 85px;
  z-index: 100;
  width: 100%;
  left: 0;
 
}

.megamenu-borderbg{
  border: 1px solid #53BB5D;
  border-radius: 10px;
  background-color: #fff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

}
.menu-header {
  cursor: default;
  font-weight: bold;
  font-size: 1.2em;
  padding: 0px 0px 10px 10px;
  margin-right: 70px;
  color: #042e59;
}

.menu-item {
  cursor: pointer;
  color: #042e59;
  padding: 5px 10px;
  font-size: 14px;
}

.menu-item:hover {
  color: #53BB5D;
}

.d-flex {
  display: flex;
}

.category i {
  font-size: 20px;
  line-height: 23px;
}

.ReactModal__Overlay {
  z-index: 150;
  background-color: rgba(4, 46, 89, 0.3) !important;
}

.input-field-login {
  width: 93% !important;
}

.button-login {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px 20px;
  white-space: nowrap;
  border-width: 2px;
  transition: 0.4s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18);
  width: 100%;
  margin: 0;
}

.popup-img-div {
  height: 100%;
  text-align: center;
  padding: 0 !important;
}

.popup-img {
  position: relative;
  /* left: 0; */
  /* height: 60px; */
  bottom: -10px;
}

.model-close {
  color: rgba(4, 46, 89, 0.3);
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.login-link {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: rgba(4, 46, 89, 0.7);
}

.line-height-25 {
  line-height: 25px;
}

.button-social {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: rgba(4, 46, 89, 0.7);
  border-radius: 5px;
  padding: 5px 20px;
  white-space: nowrap;
  border-width: 2px;
  transition: 0.4s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18); */
  width: 100%;
  margin: 0;
  border: 2px solid rgba(4, 46, 89, 0.1);
}

.button-social:hover {
  border: 2px solid #53bb5d;
}

.popup-img-social {
  position: relative;
  top: 5px;
}

.no-ac {
  color: #878787;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin: 10px 0;
}

p.login-or::before,
p.login-or::after {
  display: inline-block;
  content: "";
  border-top: 2px solid rgba(4, 46, 89, 0.1);
  width: 8rem;
  margin: 0 1rem;
  /* transform: translateY(-1rem); */
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #c0c0c0;
}

.center-text {
  text-align: center !important;
}

.login-line {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: rgba(4, 46, 89, 0.7);
}

.login-botom-icon {
  width: 56px;
  height: 48px;
  line-height: 48px;
  background: #ffffff;
  border-radius: 10px;
  text-align: center;
}

.login-botom-icon img {
  vertical-align: middle;
}

.login-bottom-text {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: rgba(4, 46, 89, 0.7);
  margin: 7px !important;
}

.width-71 {
  width: 71px !important;
}

.listing-title h5 {
  font-weight: 600;
  font-size: 22px;
  color: #042e59;
}

.view-type {
  display: flex;
}

.views {
  padding: 0 10px;
}

.view-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #878787;
}

.view-type img {
  padding: 15px;
  background: #ffffff;
  border-radius: 5px;
}

.bb-0 {
  border-bottom: 0 !important;
}

.p-15 {
  padding: 15px !important;
}

.one-line-text {
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-line-text {
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* tabs */

.react-tabs__tab--selected {
  padding: 0;
  background: #53bb5d !important;
  border-radius: 5px !important;
  border-color: transparent !important;
}

.react-tabs__tab-list {
  border-bottom: none !important;
}

.react-tabs__tab:focus {
  box-shadow: none !important;
}

.react-tabs__tab:focus:after {
  background: none !important;
}

.react-tabs__tab {
  padding: 6px !important;
  margin: 0px 5px;
  background: #fff;
  border-radius: 5px;
}

.react-tabs__tab--selected svg {
  color: #fff !important;
}

.tab-list {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.view-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-text {
  padding-right: 10px;
}

.view-grid img {
  padding: 15px;
  background: #ffffff;
  border-radius: 5px;
}

/* .view-list .tab-icon {
  padding: 10px;
  background: #ffffff;
  border-radius: 5px;
} */

.view-list .tab-icon svg {
  height: 20px;
  color: #53bb5d;
}

.views {
  padding: 0;
}

.mt--5 {
  margin-top: -5px !important;
}

.bottom-cards p {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.v-hidden {
  visibility: hidden;
}

.css-1uccc91-singleValue {
  color: #042e59 !important;
  font-weight: 700;
}
.css-6q0nyr-Svg {
  color: #042e59;
}
.header-popup .details .details-contact-block .contact-block .main-btn {
  margin-top: 10px;
}


.card-pd{
  padding: 20px;
}
.footer-copyright-link {
  margin: 0 5px;
  font-weight: 700;
}

.align-center {
  text-align: center;
}

.mega-menu .category span {
  /* line-height: 32px; */
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden; */
  padding: 0 6px 0 0;
}
.notification {
  cursor: pointer;
}
.css-g1d714-ValueContainer {
  cursor: text;
}
.header-login {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header-login img {
  margin-right: 5px;
}

@media (max-width: 992px) {
  .middle-nav-right {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .mega-menu-wrapper {
    display: none;
  }
}

@media (max-width: 768px) {
  .title-text {
    font-size: 20px;
    margin: 15px 0 0;
  }
}

@media (max-width: 480px) {
  .mega-menu-wrapper {
    display: none;
  } 
  .bottom-cards {
    display: grid;
    place-items: center;
  }
  .middle-nav-button {
    line-height: 38px;
    padding: 0 20px;
  }
  .middle-nav-left {
    width: 155px;
  }
  .nav-text {
    font-size: 16px;
  }
}

@media (max-width: 320px) {
  .middle-nav-button {
    line-height: 35px;
    padding: 0 15px;
  }
  .middle-nav-left {
    width: 170px;
  }
  .nav-text {
    font-size: 14px;
  }
}

.p-20{
  padding: 20px;
}

.w-116{
  width: 116px !important;
}

.btn-white{
  background-color: #fff !important;
}

.flex-wrap{
  flex-wrap: wrap;
}

.w-20{
  width: 20%;
}

.catgories-list-megamenu{
  color: #042e59;
}

