@import "../../style/variable";

.tab-product-section{
    margin-bottom: 20px;
    .title-content{
        margin: 15px 0;
    }
    .product-container{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        justify-content: space-between;
        margin-bottom: 25px;
        flex-wrap: wrap;
        width: 100%;
        .tab-panel-card{
            width: 90%;
            margin-bottom: 15px;
            img{
                width: 100%;
                height: 120px;
                object-fit: contain;
            }

            .title-content{
                min-height: 50px;
                margin: 15px 0;

                &:hover{
                    color: $secondaryColor;
                }
            }

            .pricing{
                font-size: 18px;
                font-weight: 700;
                color: $titleColor;

                span{
                    font-weight: 400;
                    color: $listColor;
                }
            }
        }
    }
 }


 @media (max-width: 992px){
    .tab-product-section{
        .product-container{
            padding: 0 10px;
        }
    }
 }

 @media (max-width: 480px){
    .tab-product-section{
        .product-container{
           flex-direction: column;
           padding: 0 30px;

           .tab-panel-card{
            width: 100%;
            display: flex;
            align-items: center;


            .title-content{
                min-height: auto;
            }
            img{
                width: 130px;
                height: 90px;
                margin-right: 10px;
            }
           }
        }
    }
 }
 
 @media(max-width:480px){
    .tab-product-status{
        .sub-content{
            .title-content{
                font-size: 20px;
                margin-top: 10px;
            }

            .pricing{
                text-align: center;
            }
        }

        .border-left{
            border-left: none;
        }
    }
 }