@import "./variable";

@media (max-width: 1930px) and (min-width: 1650px) {
  .container {
    min-width: 1600px;
  }
}

html {
  overflow: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}

body {
  overflow-x: hidden;
}

p {
  padding: 0;
  margin: 0;
}

.pn {
  padding: 0 !important;
}

.prn {
  padding-right: 0 !important;
}

.pln {
  padding-left: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.section-gap {
  padding: 15px 0;
}

.section-gap-top {
  padding-top: 15px;
}

.section-gap-bottom {
  padding-bottom: 15px;
}

.text-center {
  text-align: center;
}
.mb-20{
  margin-bottom: 20px;
}
.section-margin {
  margin: 15px 0;
}

.section-margin-top {
  margin-top: 15px;
}

.section-padding {
  padding: 40px 0;
}

.border-left {
  border-left: 2px solid #f5f7f9;
}

.border-bottom {
  border-bottom: 2px solid #f5f7f9;
}

.border-right {
  border-right: 2px solid #f5f7f9;
  padding-right: 15px;
}

.content {
  font-size: 14px;
  color: $contentColor;
}

.equipment-btn {
  font-size: 14px;
  padding: 10px 15px;
  background: $white;
  color: #81d794;
  border: none;
  border-radius: 5px;
}

.equipment-margin {
  margin-top: 2px;
}

.bg-white {
  background: $white;
}

.equipment-title {
  font-size: 28px;
  font-weight: 700;
  color: #042e59;
  text-align: left;
  margin: 0 0 30px;
  padding: 0 1rem;
}

@mixin btn-border-drawing($color: #ccc,
  $hover: black,
  $width: 2px,
  $vertical: top,
  $horizontal: left,
  $duration: 0.25s) {
  // box-shadow: inset 0 0 0 $width $color;
  // color: $color;
  transition: color $duration $duration/3;
  position: relative;
  border: none;
  background: transparent;
  margin-left: 10px;
  padding: 0 15px;

  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: "";
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 5px;
    #{$vertical}: 0;
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal=="left", "right", "left");

    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }

  &::after {
    $v-side: if($vertical=="top", "bottom", "top");

    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }

  &:hover {
    color: $hover;

    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }

    &::before {
      transition-delay: 0s, 0s, $duration;
    }

    &::after {
      transition-delay: 0s, $duration, 0s;
    }
  }
}

.draw-border {
  @include btn-border-drawing(#58afd1, #53bb5d, 2px, bottom, right);
}

.main-btn {
  display: flex;
  align-items: center;
  background: #53bb5d;
  color: $white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 12px;
  // display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: -2;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken($secondaryColor, 15%);
    transition: all 0.3s;

    z-index: -1;
  }

  &:hover {
    color: #fff;

    &:before {
      width: 100%;
    }
  }

  .MuiSvgIcon-root {
    margin-right: 5px;
  }
}

.nav-btn {
  background: #efa91b;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: -2;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken($secondaryColor, 15%);
    transition: all 0.3s;

    z-index: -1;
  }

  &:hover {
    color: $white !important;

    &:before {
      width: 100%;
    }
  }

  .MuiSvgIcon-root {
    margin-right: 5px;
  }
}

.main-chat {
  display: flex;
  color: $favColor;
  align-items: center;
  margin-left: 20px;
  font-size: 12px;
  border: none;
  background: transparent;

  .MuiSvgIcon-root {
    margin-right: 5px;
    font-size: 18px;
  }
}

.rating {
  background: #efa91b;
  padding: 2px 5px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 5px;

  .MuiSvgIcon-root {
    font-size: 12px;
  }
}

.company-name {
  font-size: 14px;
  font-weight: 800;
  margin-top: 5px;
  text-transform: capitalize;
  color: #042e59;
  text-align: left;
  margin: 0;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.company-address {
  font-size: 12px;
  color: #08213a;
  margin-top: 5px;
  text-transform: capitalize;
  font-weight: 600;
  display: flex;
  align-items: center;

  span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .MuiSvgIcon-root {
    font-size: 18px;
    margin-left: -3px;
  }
}

.company-supplier {
  font-size: 12px;
  color: #53bb5d;
  text-transform: capitalize;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-top: 5px;

  span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .MuiSvgIcon-root {
    font-size: 16px;
  }
}

.not-verified {
  color: red;
}

.sticky-sidebar {
  position: sticky;
  top: 20px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: $white;
  border: 5px solid $secondaryColor;
}

.child {
  &:nth-child(1) {
    border-right: 2px solid #f5f7f9;
    border-bottom: 2px solid #f5f7f9;
  }

  &:nth-child(2) {
    border-right: 2px solid #f5f7f9;
    border-bottom: 2px solid #f5f7f9;
  }

  &:nth-child(3) {
    border-right: 2px solid #f5f7f9;
    border-bottom: 2px solid #f5f7f9;
  }

  &:nth-child(4) {
    border-bottom: 2px solid #f5f7f9;
  }

  &:nth-child(5) {
    border-right: 2px solid #f5f7f9;
  }

  &:nth-child(6) {
    border-right: 2px solid #f5f7f9;
  }

  &:nth-child(7) {
    border-right: 2px solid #f5f7f9;
  }
}

.menu-img {
  max-height: 30px;
  width: 40px !important;
  object-fit: contain;
}

.mega-menu {
  // overflow-y: hidden;
  // overflow-x: scroll;

  // &::-webkit-scrollbar {
  //   display: none;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background: $scrollbarCollor;
  //   border-radius: 10px;
  // }

  // &::-webkit-scrollbar {
  //   width: 3px;
  //   height: 10px;
  // }

  // &::-webkit-scrollbar-track {
  //   // box-shadow: inset 0 0 5px grey;
  //   border-radius: 10px;
  // }
}

.sticky-header {
  position: sticky;
  top: -90px;
  z-index: 11;
  width: 100%;
}

.title-content{
  font-size: 16px;
  font-weight: 500;
  color: $listColor;

}

.product-popup-supplier {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 15px;
  text-align: center;
  color: $titleColor;
}

.product-popup {
  padding: 20px;
  height: auto;
  background: $white;
  border-radius: 5px;
}



// @media (orientation: landscape) and (max-width: 992px) {
//   html {
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     height: 100vw;
//     width: 100vh;
//     overflow-x: hidden;
//     position: absolute;
//     top: 100%;
//     left: 0;
//   }
// }

.side-bar-title{
  font-size: 14px;
  font-weight: 800;
  color: $titleColor;
  text-align: left;
  margin: 0 0 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

@media (orientation:landscape) and (max-width: 1024px) {
  .landscape-hide {
    display: none;
  }
}



@media (max-width: 992px) {
  .equipment-btn {
    font-size: 12px;
    padding: 7px 12px;
  }

  .equipment-title {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .mobile-view-hide {
    display: none;
  }

  .section-gap {
    padding: 8px 0;
  }

  .section-gap-top {
    padding-top: 8px;
  }

  .section-gap-bottom {
    padding-bottom: 8px;
  }

  .section-margin {
    margin: 8px 0;
  }

  .section-margin-top {
    margin-top: 8px;
  }

  .equipment-btn {
    font-size: 10px;
    padding: 5px 8px;
  }

  .equipment-title {
    font-size: 18px;
  }

  .section-padding {
    padding: 20px 0;
  }

  // .main-btn {
  //   padding: 8px 16px;
  // }
}

@media (max-width: 480px) {
  .main-btn {
    letter-spacing: unset;
    text-transform: capitalize;
  }

  .child {
    &:nth-child(odd) {
      border-right: 2px solid #f5f7f9;
      border-bottom: none;
      border-top: 2px solid #f5f7f9;
    }

    &:nth-child(even) {
      border-top: 2px solid #f5f7f9;
      border-bottom: none;
      border-right: none;
    }
  }

  .equipment-btn {
    font-size: 12px;
    padding: 7px 12px;
  }

  .equipment-title {
    margin: 0 0 15px;
  }

  .container {
    padding: 0;
  }

  .col {
    // padding: 0 !important;
  }
}

@media (max-width: 375px) {
  .equipment-title {
    font-size: 16px;
  }

  .container {
    padding: 0;
  }

  .product-popup {
    h4.product-popup-supplier {
      padding: 10px !important;
      text-align: center;
    }
  }
}

@media (max-width: 320px) {
  .equipment-btn {
    font-size: 10px;
    padding: 5px 8px;
  }
}

@media (orientation:landscape) and (max-width: 1024px) {
  .landscape-row {
    .col {
      &.landscape-2_5 {
        width: 25%;
      }

      &.landscape-3 {
        width: 30%;
      }

      &.landscape-7 {
        width: 70%;
      }
      &.landscape-10 {
        width: 100%;
      }
    }
  }
}