$secondaryColor:#53BB5D;
$white: #fff;
$productTitle:#4E4E4E;
$contentColor:#4e4e4eb3;
$titleColor:#042e59;
$listColor: #042e59b3;
$inputColor:rgba(4, 5, 5, 0.7);
$placeholderColor:#042e594d;
$favColor:#065DC4;
$imageHoverBg:#ebebeb3b;
$shadow:  0 10px 15px -3px #d2d2d21a, 0 4px 6px -2px #0000000d;
$productBorderDesktop:#042e591a;
$productBorderMobile:#d6d6d6d6;
$imageOverlay:#0707072e;
$listHoverBg:#eeeeee6e;
$scrollbarCollor:#c5c5c5d4;
$shimmerlight:#8080800a;
$shimmergrey:#e8e7e880;
$shimmercard:#cec8c821;
$infoColor:#efa91b;
$shimmerdark:#63626214 ;
$shimmermidlight:#e0dede40;
$shimmerbtn: #d2cfcf21;
$shimmerimg: #f4f3f3;
$border-color: #042e591a;