@import "../variable";

.hamburger-overlay {
    height: 100vh;
    width: 100vw;
    background-color: transparent;
    z-index: 11111
}

.hamburger {
    position: absolute;
    top: 15px;

    .MuiSvgIcon-root{
        display: none;
    }

    .makeStyles-menuButton-4 {
        .MuiSvgIcon-root {
            color: $white;
            font-size: 2.3rem;
            display: none;
        }
    }

    .MuiListItemIcon-root {
        min-width: unset;
    }

    .MuiListItem-root {
        padding: 5px 15px;
    }

    .MuiIconButton-root {
        // width: 100%;
        // padding: 5px;
    }

    .close-icon {
        min-width: 215px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .hamburger-title {
            // margin-left: 12PX;
            font-size: 18px;
            font-weight: 600;
            color: $listColor;
            height: unset;
            line-height: unset;
        }

        .MuiSvgIcon-root {
            margin-right: -5px;
            display: none;
        }
    }

    .MuiIconButton-edgeStart {
        // margin-left: -15px;
    }

    .makeStyles-drawerHeader-8 {
        padding: 0 3px 0 5px;
    }

    .MuiIconButton-label {
        .MuiSvgIcon-root {
            // color: white;
        }
    }

    .MuiToolbar-gutters {
        padding: 0 18px;
    }
}

@media(max-width:320px) {
    .categories-mobile {

        .hamburger {
            .makeStyles-drawerHeader-8 {
                padding: 0 15px;
            }
        }
    }

    .hamburger {
        .makeStyles-drawerHeader-8 {
            padding: 0 2px 0 5px;
        }
    }
}