@import "../variable";

.single_carousal_placeholder {
  height: 370px;
  background: #c8c4c4;
  background-image: url("../../images/logo-white.png");
  background-repeat: no-repeat;
  align-content: center;
  background-position: center;
}

.js-image-zoom__zoomed-image {
  z-index: 111111;
}

.details {
  .details-section {
    background: $white;
    padding: 20px;
    border-radius: 10px;



    .details-btn-block{
      .main-btn{
        margin-top: 10px;
      }
    }
    .thumb-image-block {
      &:hover {
      }

      .thumb-image {
        img {
          height: 70px;
          width: 60%;
          object-fit: contain;
          object-position: center;
          border: 2px solid rgba(4, 46, 89, 0.05);
          border-radius: 10px;
          padding: 5px;
          margin-bottom: 5px;
        }
      }
    }

    .details-image {
      cursor: zoom-in;

      img {
        height: 403px !important;
        width: 100% !important;
        object-fit: contain;
        object-position: center;
        cursor: zoom-in;
      }
    }

    .image-hover {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #042e5966;
      padding: 10px 0 0;

      .MuiSvgIcon-root {
        margin-right: 5px;
        font-size: 22px;
      }
    }

    .details-block {
      .details-title {
        font-size: 22px;
        font-weight: 700;
        color: $listColor;
        text-align: left;
        margin: 0;
    
      }

      .details-price-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $listColor;
        background: #eeeeeead;
        padding: 10px;

        margin: 20px 0;

        .details-price-area {
          .price {
            font-size: 18px;
            font-weight: 800;
            color: $titleColor;
            margin: 0;
          }

          span {
            font-size: 12px;
            font-weight: 300;
          }

          .piece {
            font-size: 12px;
            font-weight: 800;
          }
        }

        .price-btn {
          font-size: 12px;
          padding: 8px 10px;
          color: $secondaryColor;
          background: $white;
          border: none;
          border-radius: 5px;
          // margin-right: 40px;
        }
      }
.null-price{
  justify-content: flex-start;
  .draw-border{

    margin: 0;
  }
}
      .details-btn-block {
        display: flex;
      }

      .details-info-block {
        margin-top: 20px;

        .details-info {
          display: flex;
          font-size: 12px;
          color: $listColor;
          margin-top: 10px;

          .first-info {
            width: 50%;
            font-weight: 700;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }

          .second-info {
            width: 50%;
            font-weight: 600;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
        }
      }
    }
  }

  .details-contact-block {
    background: $white;
    border-radius: 10px;

    .block-padding {
      padding: 20px;
    }

    .contact-info {
      display: flex;
      justify-content: space-between;

      .contact-logo {
        position: relative;

        img {
          border: 2px solid rgba(4, 46, 89, 0.3);
          border-radius: 10px;
          padding: 10px;
          height: 60px;
          width: 60px;
        }

        .rating {
          position: absolute;
          top: 80%;
          left: 20%;
          font-size: 8px;

          .MuiSvgIcon-root {
            font-size: 10px;
          }
        }
      }

      .contact-loaction {
        padding: 0 0 0 15px;
        width: 100%;
        text-transform: capitalize;

        .company-name {
          // font-size: 12px !important;
        }
      }
    }

    .conatct-reminder {
      font-size: 16px;
      color: $listColor;
      max-height: 50px;
      min-height: 50px;
      font-weight: 700;
      padding: 10px 0 0;
      line-height: 1.3rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .contact-block {
      .error {
        font-size: 10px;
        color: #ff0000;
      }

      .product-popup-title {
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 15px;
        height: unset;
        color: $titleColor;
        text-align: center;
      }

      .contact-input {
        height: unset;
        width: 100%;
        font-size: 0.9rem;
        box-sizing: border-box;
        padding: 7px;
        margin: 9px 0 0;
        outline: none;
        background-color: transparent;
        transition: box-shadow 0.3s, border 0.3s;
        box-shadow: none;
        background: #ffffff;
        border: 2px solid rgba(4, 46, 89, 0.1);
        border-radius: 5px;
        overflow: hidden;
        resize: none;

        &:active,
        &:focus {
          border: 2px solid $listColor;
        }
      }

      .input-field {
        margin: 0;

        .contact-input {
          position: relative;
          padding-left: 80px;
        }

        .input-field-image {
          display: flex;

          position: absolute;
          top: 16px;
          left: 1px;

          img {
            height: 18px;
            width: 30px;
            object-fit: contain;
          }

          span {
            font-size: 13px;
            font-weight: 700;
            color: $listColor;
            // margin-left: 5px;
            line-height: 22px;
          }
        }
      }

      .main-btn {
        width: 100%;
        font-size: 14px;
        display: block;
        font-weight: 500;
        margin-top: 5px;
      }
    }

    .contact-request {
      display: flex;
      justify-content: space-between;
      padding: 10px 0 0;

      p {
        font-size: 12px;
        color: $listColor;
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 992px) {
  .details {
    .pln {
      padding: 0 !important;
    }

    .prn {
      padding: 0 !important;
    }

    .details-section {
      .details-image {
        img {
          height: 360px !important;
        }
      }
      .details-block {
        .details-btn-block {
          justify-content: space-between;
          .main-btn {
            padding: 10px;
          }
          .draw-border {
            padding: 0;
          }
        }
        .details-title {
          // font-size: 20px;
        }

        .details-price-block {
          // margin: 15px 0;

          .details-price-area {
            .price {
              // font-size: 15px;
            }

            span {
              // font-size: 10px;
            }

            .piece {
              // font-size: 12px;
            }
          }

          .price-btn {
            // margin-right: 20px;
          }
        }

        .details-info-block {
          .details-info {
            font-size: 14px;
          }
        }
      }
    }

    .details-contact-block {
      // margin-top: 30px;

      .conatct-reminder {
        font-size: 18px;
        min-height: unset;
        max-height: unset;
        line-height: 1.5rem;
      }

      .company-name {
        font-size: 16px;
      }

      .company-address {
        font-size: 14px;
        margin-top: 5px;
      }

      .company-supplier {
        font-size: 14px;
      }

      .contact-info {
        .contact-logo {
          .rating {
            top: 77%;
          }
        }
      }

      .contact-block {
        .contact-input {
          padding: 15px;

          &::placeholder {
            // font-size: 18px;
          }
        }

        .main-btn {
          width: unset;
          font-size: 16px;
        }
      }

      .contact-request {
        p {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .details {
    .details-section {
      margin: 15px 0;
      .details-image {
        img {
          height: 300px !important;
        }
      }
      .thumb-image-block {
        .thumb-image {
          img {
            width: 100%;
          }
        }
      }

      .details-block {
        .details-title {
          font-size: 18px;
        }

        .details-price-block {
          .details-price-area {
            .price {
              font-size: 14px;
            }

            span {
              font-size: 10px;
            }

            .piece {
              font-size: 10px;
            }
          }

          .price-btn {
            font-size: 10px;
          }
        }

        .details-btn-block {
          display: unset;
          justify-content: space-between;

          .main-btn {
            // width: 100%;
            justify-content: center;
            padding: 10px 20px;
          }
          .draw-border {
            margin: 15px 0 0;
          }
          .main-chat {
            padding: 15px 0 0;
            margin: 0;
          }
        }

        .details-info-block {
          .details-info {
            font-size: 12px;
          }
        }
      }
    }

    .details-contact-block {
      .company-name {
        font-size: 14px;
      }

      .company-address {
        font-size: 12px;
      }

      .company-supplier {
        font-size: 12px;
      }

      .conatct-reminder {
        font-size: 16px;
      }

      .contact-block {
        .contact-input {
          padding: 8px 10px;
          margin: 5px 0 0;
          font-size: 12px;

          &::placeholder {
            // font-size: 16px;
          }
        }

        .input-field {
          .input-field-image {
            left: 2%;
            top: 12px;
            span {
              font-size: 12px;
            }
          }
        }

        .main-btn {
          font-size: 12px;
          padding: 10px;
        }
      }

      .contact-request {
        padding: 5px 0 0;

        p {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .details {
    .details-section {
      padding: 15px;
      margin: 5px 0 0;
      .thumb-image-block {
        // display: none;
       
      }

      .details-image {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 300px !important;
          width: 80%;
        }
      }

      .image-hover {
        margin-top: 10px;
        display: none;
      }

      .details-block {
        padding: 20px 0 0;

        .details-title {
          font-size: 24px;
        }

        .details-btn-block {
          // display: unset;
          // display: none;
          display: flex;
          justify-content: space-between;

          .main-btn {
            // width: 100%;
            justify-content: center;
            // padding: 10px;
          }
          .draw-border {
            padding: 0;
            margin: 0;
          }
          .main-chat {
            width: 100%;
            padding: 15px 0 0;
            margin: 0;
          }
        }

        .details-price-block {
          margin: 10px -20px;
          padding: 10px 20px;

          .details-price-area {
            .price {
              font-size: 20px;
            }

            span {
              font-size: 12px;
            }

            .piece {
              font-size: 12px;
            }
          }

          .price-btn {
            font-size: 14px;
            margin: 0;
          }
        }

        .details-info-block {
          padding: 10px 0 0;
          margin-top: 0;

          .details-info {
            font-size: 14px;
            margin-top: 5px;
          }
        }
      }
    }

    .details-contact-block {
      margin-top: 15px;
      padding: 10px;

      .details-info {
        .contact-block {
          display: none;
        }
      }

      .block-padding {
        padding: 0px;
      }

      // display: none;

      .contact-info {
        justify-content: unset;
        padding: 10px 10px 5px;

        .contact-logo {
          .rating {
            top: 82%;
          }
        }
      }

      .company-name {
        font-size: 18px;
      }

      .company-address {
        font-size: 12px;

        .MuiSvgIcon-root {
          font-size: 16px;
        }
      }

      .company-supplier {
        font-size: 12px;

        .MuiSvgIcon-root {
          font-size: 16px;
        }
      }

      .conatct-reminder {
        font-size: 14px;
        padding: 15px 0 10px;
        display: none;
      }

      .contact-block {
        // display: none;

        .main-btn {
          font-size: 12px;
        }

        .contact-request {
          p {
            font-size: 10px;
          }
        }
      }

      .contact-request {
        // display: none;
      }
    }
  }
}

@media (max-width: 375px) {
  .details {
    .details-section {
      .thumb-image-block {
        .thumb-image {
          img {
            height: 50px;
            margin: 0;
          }
        }
      }

      .details-image {
        img {
          height: 250px !important;
        }
      }

      .image-hover {
        font-size: 12px;

        .MuiSvgIcon-root {
          font-size: 18px;
          margin-right: 2px;
        }
      }

      .details-block {
        .details-title {
          font-size: 20px;
        }

        .details-price-block {
          .details-price-area {
            .price {
              font-size: 18px;
            }
          }

          .price-btn {
            font-size: 12px;
          }
        }

        .details-info-block {
          .details-info {
            font-size: 12px;
          }
        }
      }
    }

    .details-contact {
      .company-name {
        font-size: 16px;
      }
    }
    .details-contact-block {
      .contact-block {
        .input-field {
          .input-field-image {
            top: 14px;
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .details {
    .details-section {
      .thumb-image-block {
        .thumb-image {
          img {
            height: 40px;
          }
        }
      }

      .details-image {
        img {
          height: 200px !important;
        }
      }
    }

    .details-contact {
      .contact-info {
        .contact-logo {
          .rating {
            top: 62%;
          }
        }
      }
    }
  }
}

.marked-price {
  margin-left: 1rem !important;
  font-size: 18px !important;
  font-weight: 800 !important;
  color: $titleColor;
}

@media (orientation:landscape) and (max-width: 1024px) {
  .details{
    .details-section{
    margin-bottom: 15px;
    }
    .pln{
      padding: 0 ;
    }
    .prn{
      padding: 0;
    }
  }
}