@import "../../style/variable";

.tab-product-status {
  .tab-panel-card {
    .product-status-content {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 0 30px;

      .border-rt{
        border-right: 1px solid #0000004f;
      }
      img {
        width: 230px;
        height: 140px;
        object-fit: cover;
      }

      .sub-content {
        padding: 0 40px;
        margin: auto 0;

        .pricing {
          font-weight: 700;
          color: $titleColor;
        }
      }

      .content-wrap{
        display: flex;
      }

   
    }
  }

  .border-wrap {
    border: 1px solid $border-color;
    display: flex;

    .product-listview {
      padding: 24px;
      border-right: 1px solid $border-color;
      img {
        width: 100%;
        margin-bottom: 15px;
      }

      .title-content {
        margin: 0;
        text-align: center;
      }
    }
  }
}


@media (max-width:480px){
  .tab-product-status{
    .product-status-content{
      flex-direction: column;
      align-items: initial !important;
      img{
        margin: 0 auto;
      }

      .sub-content{
          .title-content{
                text-align: center;
    margin-bottom: 5px !important;
          }
      }
      .content-wrap{
        margin-top: 10px;
        border-top: 1px solid $border-color;
        padding-top: 10px;
        justify-content: space-between;

        .sub-content{
          padding: 0 15px;

        
        }
        .border-left{
          border: none;
        }
      }
    }
  }
  .border-wrap{
    flex-direction: column;
  }
}