@import "../style/_variable.scss";




.google-login-section{
    button{
        box-shadow: none !important;

        span{
            font-size: 16px;
            text-transform: capitalize;
            color: $listColor;
            padding: 0;
        }
    }
}