.mobile-view-header{
  margin-bottom: 47px !important;
}

.mobile-nav-style {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 2;
}

.nav-row {
  line-height: 55px;
  display: flex;
  height: 55px;
  justify-content: space-between;
}

.nav-col {
  display: flex;
  justify-content: space-between;
}

.span-content {
  height: 100%;
  width: 15%;
}

.mobile-back {
  font-size: 2.1rem;
  line-height: 0;
  position: absolute;
  top: 0;
  display: inline-block;
  margin: 1px 0;
  padding: 18px 20px;
  left: 0;
  width: 60px;
}

.category-style {
  line-height: 55px;
  color: #4e4e4e;
  width: 65%;
  text-align: left;
  font-weight: 600;
  font-size: 22px;
}

.spanTwo-content{
    line-height: 55px;
    width: 20%;
}

.mobile-search-button{
    padding:20px 5px 16px 15px;
    position:relative;
    top:0;
}

.mobile-input-field{
    z-index: 1;
    background-color: #fff;
    height: 45px;
  
}

.icon-height{
    height: 30px;
}

.label-height{
    height: 30px;
}


 .navbar-title{
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
 }