@import "../variable";

.filter-nav {
  line-height: 56px;
  height: 56px;
}

.MuiAppBar-colorPrimary {
  background-color: transparent !important;
  color: transparent !important;
}

.MuiPaper-elevation4 {
  box-shadow: unset !important;
}

.MuiAppBar-positionFixed {
  position: unset !important;
}

.MuiAppBar-root {
  width: unset !important;
}

.makeStyles-root-1 {
  display: inline-block !important;
}

.MuiIconButton-edgeEnd {
  padding: 0 !important;
  margin: 0 !important;

  .MuiSvgIcon-root {
    font-size: 2rem;
    color: $listColor;
  }
}

.makeStyles-drawerHeader-8 {
  .MuiSvgIcon-root {
    font-size: 2rem;
    color: $listColor;
  }
}

.MuiTypography-displayBlock {
  color: $listColor;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.MuiListItem-root {
  flex-direction: row-reverse;

  .MuiSvgIcon-root {
    color: $listColor;
  }
}

.MuiListItemIcon-root {
  min-width: unset;
}

.filter-icon {
  display: flex;
  align-items: center;

  .filter-icon-text {
    font-size: 18px;
    font-weight: 600;
    color: $listColor;
    margin-left: 5px;
  }
}

.filter-btn {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 17%;

  button {
    padding: 15px 30px;
    width: 50%;
    border: none;
    font-size: 14px;
  }

  .reset-btn {
    color: rgba(239, 79, 44, 1);
    background: rgba(239, 79, 44, 0.05);
  }

  .confirm-btn {
    background: $secondaryColor;
    color: $white;
  }
}

.MuiIconButton-root:hover {
  background-color: none !important;
}

@media (max-width: 480px) {
  .makeStyles-root-1 {
    width: 60px;
  }

  .filter-btn {
    width: 50%;
  }
}

.makeStyles-drawer-16 {
  width: 0 !important;
}

.makeStyles-content-19 {
  display: none;
}

@media (max-width: 375px) {
  .makeStyles-drawerHeader-8 {
    .MuiSvgIcon-root {
      font-size: 1.8rem;
    }
  }

  .filter-icon {
    .filter-icon-text {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .filter-btn {
    width: 64%;
  }
}

@media (max-width: 320px) {
  .MuiIconButton-edgeEnd {
    .MuiSvgIcon-root {
      font-size: 1.55rem;
    }
  }

  .filter-btn {
    width: 75%;
  }
}

.side-bar {
  background: $white;
  border-radius: 10px;
  // padding: 0 20px 20px;
  // max-height: 600px;
  overflow-y: scroll;
  min-height: 582px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dbdbdb4f;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #dbdbdb4f;
  }

  .categories-segment-header {
    display: flex;
    padding: 10px;
  }

  .accordion-section {
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 5px 0 !important;
    }

    .MuiPaper-elevation1 {
      box-shadow: none !important;
      border-bottom: 1px solid #e0e0e0;
    }
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 45px;
    }
  }
  .side-bar-block {
    width: 100%;
    .side-bar-title-block {
      display: flex;
      align-items: center;

      .MuiSvgIcon-root {
        font-size: 18px;
        margin: 0 0 10px;
      }
    }

    .side-bar-title {
      font-size: 14px;
      font-weight: 600;
      text-decoration: underline;
      color: $titleColor;
      text-align: left;
      margin: 0 0 10px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      padding: 0;
    }

    .side-bar-list {
      margin: 0 0 0;

      .selected-index {
        background: #eeeeee6e;
        color: #042e59;
        border-radius: 8px 8px 0 0;
        border-bottom: 1px solid #80808047;

        &:hover {
          background: #eeeeee6e;
          color: #042e59;
          border-radius: 8px 8px 0 0;
        }
      }

      .selected-element-details {
        background-color: #eeeeee6e;
        padding: 10px;
        border-radius: 0 0 8px 8px;
        padding-top: 20px;

        .checkbox-filter {
          margin: 10px 0;
        }
      }
      li {
        font-size: 14px;
        color: $listColor;
        font-weight: 700;
        padding: 10px;

        &:hover {
          background: $listHoverBg;
          color: $titleColor;
          border-radius: 8px;
        }
      }

      .list-type {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        margin: 15px 0;
      }

      .more {
        color: #53bb5d;
        display: flex;
        align-items: center;

        .MuiSvgIcon-root {
          font-size: 10px;
          margin-left: 3px;
        }
      }
    }

    .title-padding {
      padding: 20px 0;
    }
  }

  .side-bar-filter {
    padding: 20px 0;

    .input-range__slider {
      background: $secondaryColor;
      border: 1px solid $secondaryColor;
    }

    .input-range__track--active {
      background: $secondaryColor;
    }

    .input-range__label {
      font-weight: 600;
      color: $listColor;
    }

    .input-range__slider-container:last-of-type .input-range__label-container {
      left: -100%;
    }

    .input-range__slider-container:first-of-type .input-range__label-container {
      left: 0;
    }

    .input-range__label--max .input-range__label-container {
      left: 0;
    }

    .input-range__label--min .input-range__label-container {
      left: 0;
    }
  }
}

@media (max-width: 992px) {
  .side-bar {
    .side-bar-block {
      .side-bar-title {
        // font-size: 12px;
        // line-height: 1.5rem;
      }
    }

    .side-bar-block {
      .side-bar-list {
        li {
          // font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .side-bar {
    padding: 0 10px 20px;
    display: none;
  }
}

@media (max-width: 480px) {
  .side-bar-filter {
    padding: 30px;

    .input-range__slider {
      appearance: none;
      border-radius: 100%;
      cursor: pointer;
      display: block;
      height: 15px;
      width: 15px;
      margin-left: -0.5rem;
      margin-top: -0.65rem;
      transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    }

    .input-range__track {
      height: 0.2rem;
    }

    .input-range__slider {
      background: $secondaryColor;
      border: 1px solid $secondaryColor;
    }

    .input-range__track--active {
      background: $secondaryColor;
    }

    .input-range__label {
      font-weight: 600;
      color: $listColor;
    }

    .input-range__slider-container:last-of-type .input-range__label-container {
      left: -100%;
    }

    .input-range__slider-container:first-of-type .input-range__label-container {
      left: 0;
    }

    .input-range__label--max .input-range__label-container {
      left: 0;
    }

    .input-range__label--min .input-range__label-container {
      left: 0;
    }
  }
}
