@import "../../style/variable";

.tab-details-section {
    .user-info {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .fa-phone {
        transform: scaleX(-1);
      }
      i {
        font-size: 25px;
        color: $secondaryColor;
      }

      .details-info {
        margin-left: 15px;

        p {
          font-size: 14px;
          color: #7c8798;
          font-weight: 500;
        }
        h5 {
          font-size: 16px;
          color: #042e59;
          line-height: 24px;
          margin: 0;
        }
      }
    }
  }