@import "../variable";


.placeholder-image {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        background: #c8c4c4;
        // background-image: url("../../images/logo-white.png");
        // background-repeat: no-repeat;
        // -ms-flex-line-pack: center;
        // align-content: center;
        // background-position: center;
        // background-size: 100px 50px;
        width: 80% !important;
        object-fit: contain;
    }
}


@media(max-width:950px) {
    .placeholder-image {
        img {
            background-size: 65px 30px;
        }
    }
}

@media(max-width:480px) {
    .placeholder-image {
        img {
            background-size: 100px 50px;

        }
    }
}

@media(max-width:375px){
    .placeholder-image {
        img {
            background-size: 65px 35px;

        }
    }
}