.search-icon {
  position: absolute;
  top: 27px;
  right: 420px;
  color: #6d6d6d;


  svg {
    width: 20px;
    color: #d3d3d3;
  }
}
@media (max-width: 1024px) {
  .search-icon {
    top: 18px;
    right: 45px;

    svg {
      width: 25px;
      color: #6d6d6d;
    }
  }
}

@media (max-width: 992px) {
  .search-icon {
    top: 19px;
    right: 36px;

    svg {
      width: 20px;
      color: #6d6d6d;
    }
  }
}

@media (max-width: 768px) {
  .search-icon {
    top: 19px;
    right: 36px;

    svg {
      width: 20px;
      color: #6d6d6d;
    }
  }
}

@media (max-width: 480px) {
  .search-icon {
    top: 22px;
    right: 20px;

    svg {
      width: 20px;
      color: #6d6d6d;
    }
  }
}

@media (max-width: 375px) {
  .search-icon {
    top: 21px;
    right: 20px;

    svg {
      width: 20px;
      color: #6d6d6d;
    }
  }
}

@media (max-width: 360px) {
  .search-icon {
    top: 21px;
    right: 20px;

    svg {
      width: 20px;
      color: #6d6d6d;
    }
  }
}
@media (max-width: 320px) {
  .search-icon {
    top: 21px;
    right: 20px;

    svg {
      width: 20px;
      color: #6d6d6d;
    }
  }
}



@media (orientation:landscape) and (max-width: 1024px){
 .search-icon{
   top:76px;
   color:#6d6d6d;
 }
}